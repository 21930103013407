.c-pagination {
  font-size: 1.6rem;
  & a {
    display: inline-block;
    padding: 8px 16px;
    transition: .2s;
    &:hover {
      background: #fafafa;
    }
  }
  &__ctrl {
    display: flex;
    justify-content: space-between;
  }
  &__newer,
  &__older {
    flex: 1;
  }
  &__newer {
    & a {
      &::before {
        content: "";
        display: inline-block;
        position: relative;
        top: -1px;
        width: 4px;
        height: 4px;
        margin-right: 4px;
        border: solid currentColor;
        border-width: 2px 0 0 2px;
        transform: rotate(-45deg);
      }
    }
  }
  &__older {
    text-align: right;
    & a {
      &::after {
        content: "";
        display: inline-block;
        position: relative;
        top: -1px;
        width: 4px;
        height: 4px;
        margin-left: 4px;
        border: solid currentColor;
        border-width: 2px 2px 0 0;
        transform: rotate(45deg);
      }
    }
  }
  &__count {
    display: block;
    text-align: center;
  }
}
