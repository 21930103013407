/* foundation */
@import "foundation/variable/breakpoint.css";
@import "foundation/variable/color.css";
@import "foundation/variable/font-family.css";
@import "zoom.js/css/zoom.css";
@import "slick-carousel/slick/slick.css";
@import "foundation/lib/slick-custom.css";
@import "foundation/base/normalize.css";
@import "foundation/base/base.css";

/* fonts */
@import "fonts/opensans.css";

/* layout */
@import "layout/header.css";
@import "layout/nav.css";
@import "layout/main.css";
@import "layout/footer.css";

/* object */
@import "object/component/title.css";
@import "object/component/links.css";
@import "object/component/article.css";
@import "object/component/avatar.css";
@import "object/component/time.css";
@import "object/component/tag.css";
@import "object/component/pagination.css";

@import "object/project/title.css";
@import "object/project/subtitle.css";
@import "object/project/tag-title.css";
@import "object/project/copyright.css";
@import "object/project/list-article.css";
@import "object/project/menu.css";
@import "object/project/author.css";
@import "object/project/pagination.css";
@import "object/project/article.css";
@import "object/project/notfound.css";
@import "object/project/screen-reader-text.css";
@import "object/project/related.css";

@import "object/utility/display.css";
@import "object/utility/align.css";
