.c-article {
  &__title {
    font-size: 2.4rem;
    & a {
      color: var(--color-text);
      &:hover {
        color: var(--link-hover);
      }
    }
  }
  &__meta {
    font-size: 1.6rem;
    line-height: 1;
  }
  &__summary {
    font-size: 1.4rem;
    color: #999;
    line-height: 1.57;
    & p {
      margin: 0;
    }
  }
  &__btn {
    display: inline-block;
    padding-bottom: 4px;
    font-size: 1.6rem;
    &::after {
      content: "";
      display: inline-block;
      margin-left: 3px;
      width: 5px;
      height: 5px;
      border: solid currentColor;
      border-width: 1px 1px 0 0;
      transform: rotate(45deg);
    }
  }
}
