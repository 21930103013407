* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  color: var(--color-text);
  font-size: 1.0rem;
  font-family: var(--SanFrancisco);
  line-height: 1.57;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 300;
  font-family: var(--OpenSans);
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2.0rem;
}

h5 {
  font-size: 1.8rem;
}

h6 {
  font-size: 1.6rem;
}

p {
  font-size: 1.6rem;
}

a {
  color: var(--link-color);
  text-decoration: none;
  &:hover {
    color: var(--link-hover);
  }
}

ul {
  & li {
    list-style: disc;
  }
}

ol {
  & li {
    list-style: decimal;
  }
}

li {
  font-size: 1.6rem;
}

dt {
  margin-top: 16px;
  font-size: 1.6rem;
}

dd {
  margin: 8px 0 0 20px;
  font-size: 1.6rem;
}

pre {
  display: block;
  padding: 12px;
  border-radius: 3px;
  background-color: #f8f8f8;
  font-size: 1.2rem;;
  word-wrap: break-word;
  overflow: auto;
}

code {
  line-height: 1.8;
  font-size: 1.4rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1.6rem;
}

th, td {
  padding: 8px;
  border: 1px solid #eee;
}

th {
  background-color: #fafafa;
  font-weight: normal;
}

del {
  color: #999;
}

blockquote {
  margin: 0;
  padding: 8px 12px;
  border-left: 3px solid #ccc;
  & * {
    margin: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}
