.p-menu {
  margin-top: 40px;
  &__lists {
    display: block;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    @media (--sm) {
      display: flex;
    }
  }
  &__listitem {
    margin: 12px 0 0 0;
    list-style: none;
    @media (--sm) {
      margin: 24px 24px 0 0;
    }
    & a {
      padding-bottom: 4px;
      &:hover {
        border-bottom: 1px solid currentColor;
      }
    }
    & ul {
      padding-left: 12px;
      & li {
        margin: 4px 0 0;
        &::before {
          content: "-";
          margin-right: 4px;
        }
      }
    }
  }
  &__parent {
    & span {
      color: var(--link-color);
    }
  }
}
