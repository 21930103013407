.p-related {
  margin-bottom: 100px;
  &__list {
    padding: 0;
    font-size: 0;
  }
  &__item {
    display: inline-block;
    width: 230px;
    height: 150px;
    margin-right: 15px;
    list-style: none;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-child(n+4) {
      margin-top: 15px;
    }
    & a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 8px;
      background-size: cover;
      background-color: #92c6f2;
      color: #fafafa;
      font-weight: normal;
      font-family: 'Open Sans', sans-serif;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .3);
        transition: .1s;
      }
      &:hover {
        &::before {
          background: rgba(0, 0, 0, .6);
        }
      }
    }
    & span {
      position: relative;
    }
  }
}
