.c-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  & img {
    max-width: 100%;
    height: auto;
  }
}
