.p-author {
  display: block;
  position: relative;
  width: 100%;
  margin: 20px auto 0;
  @media (--sm) {
    display: flex;
    margin: 80px auto 0;
  }
  &::before {
    content: "* * *";
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 1.4rem;
    color: #999;
    @media (--sm) {
      top: -50px;
    }
  }
  &__avatar {
  }
  &__name {
    font-size: 2.8rem;
  }
  &__body {
    flex: 1;
    @media (--sm) {
      padding-left: 16px;
    }
    & p {
      margin: 0;
    }
  }
}
