.p-tag-title {
  display: inline-block;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid currentColor;
  color: var(--color-text);
  font-size: 2.4rem;
  &::before {
    content: "#";
    display: inline-block;
    margin-right: 4px;
  }
}
